<template>
  <div class="introduce">

    <h1 class="index-h2">产品介绍</h1>

    <el-row>
      <el-col class="col"
              :xs="24"
              :md="12">

        <h2 class="">妙智能钢琴教室</h2>
        
        <div class="hr"></div>
        
        <p>          
音乐是开启人类智慧的钥匙，音乐在儿童的成长过程中起到了重要作用。而在所有乐器中，钢琴享有着乐器之王的称号。
</p>
        <br>
        <p>    
它能够促进孩子大脑的发育，开发孩子的音乐兴趣，锻炼手指和身体的协调性，让生活变得更有品味，对生活的热爱可以通过弹奏表达出来。

        </p>
        
      </el-col>


      <el-col :xs="24"
              :md="12">

        <div class="  hvr-grow">
          <el-image :src="url"
                    
                    class="img-box ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>

      </el-col>

    </el-row>
 <el-row>
      <el-col class="col"
              :xs="24"
              :md="12">

        <h2 class="">妙智能架子鼓教室</h2>
        
        <div class="hr"></div>
        
        <p>          
学生端：运行于Android盒子，受主机控制，通过连接钢琴，实时反馈弹奏对错情况，实现智能练琴。
</p>
        <br>
        <p>    
老师端：采用C/S结构，局域网一对多模式，实现课件视频播放、乐理知识学习、课堂考核，课堂练习等功能。
老师端可以直接使用EXE安装包安装，安卓智能盒子则使用APK安装包进行安装。该端是一个播放客户端，用于老师现场授课教学、课前预习演练。主要功能有登录管理、设备连接管理、课程进度管理、课程播放功能、设置以及个人中心。
 

        </p>
        
      </el-col>


      <el-col :xs="24"
              :md="12">

        <div class="  hvr-grow">
          <el-image :src=" require('@/assets/ee20-2.jpg')"
                    
                    class="img-box ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
        </div>

      </el-col>

    </el-row>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src="url2"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

 <h1 class="index-h2">美育案例</h1>
 <el-row>
   <el-col class="col"
              :xs="24"
              :md="12">
      <el-image :src=" require('@/assets/ee21.jpg')"
                    
                    class="img-box2 ">
            <div slot="placeholder"
                 class="image-slot">
              加载中<span class="dot">...</span>
            </div>
          </el-image>
         </el-col>

    </el-row>

  

  </div>
</template>


<script>
export default {
  data () {
    return {
      url: require('@/assets/ee20-1.jpg'),
       url2: require('@/assets/ee20.jpg')

    }
  },
  methods: {

  },
  metaInfo: {
    title: '北京启迪邦妙文体科技有限公司-美育教育', 
    meta: [{                 
      name: '启迪邦妙,妙音乐教室,智能音乐教室,启迪春雨,启迪智慧教育,智能钢琴,钢琴陪练,钢琴考级,智能架子鼓,腰旗橄榄球',
      content: '北京启迪邦妙文体科技有限公司长年致力于青少年素质教育智能化平台建设工作，拥有多项智能教育领域专利、软件著作权等产品成果，是启迪控股集团内专业从事青少年素质教育服务的控股子公司。'
    }]      
  } 
  //   components: {

  //   },
}
</script>

<style lang="less" scoped>
.introduce {
  padding-bottom: 100px;
}
.el-row {
  display: flex;
  align-items: center;
}
.col {
  text-align: left;
   
  h3 {
    margin-bottom: 5px;
  }
  .hr {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 2px;
    background-color: whitesmoke;
  }
  .msg {
    font-size: 14px;
  }

  p {
    font-size: 16px;
    line-height: 35px;
  }

  .img-box{
    width: 300px;
  }
  .img-box2{
    width: 1200px;
  }
}
</style>
<style lang="less" >
</style>